import React from "react";
import Layout from "../components/reusable/Layout";
import { Link, graphql } from "gatsby";
import Seo from "../components/reusable/Seo";
import { useTranslation } from "react-i18next";
import { Hero, InfoSection, ContactSection } from "../styles/pages/usablity";
import { Bg, Text } from "../components/ui";
import {Button} from "@mui/material";
const Pledge = () => {
  const { t } = useTranslation("ourPledge");
const pieza = "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fusability%2F%C2%BFTe%20gustari%CC%81a%20conocer%20ma%CC%81s_.png?alt=media&token=10e8d223-85c0-4c39-83ad-8d37996abf41";
const piezaHero = "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FNos%20mueve%20el%20impacto%201.png?alt=media&token=66b27e7a-c563-40cf-a383-c5919cbdebf1";
  return (
    <Layout>
      <Seo
        title="Queremos que digas “encontré el trabajo de mis sueños”"
        description="Buscamos promover con nuestros productos, la idea de que todos pueden tener el trabajo de sus sueños."
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FSEO%2FTAG3.png?alt=media&token=1e0186ca-89d1-4e65-9386-e05e7e7c4ce7"
      />
        <Bg.Linear>
      <Hero>
        <div className="main-landing-container-row">
          <article>
            <Text.SubTitle
                text={t("usability")}
                fontSize={"23px"}
                sx={{display: "block", marginBottom:'5px', textAlign:'left'}}
            />
            <Text.Title
              text={t("title")}
              fontSize={"38px"}
              sx={{display: "block", marginBottom:'30px', textAlign:'left'}}
           />
            <Text.Body
                text={t("simpleDesc")}
                fontSize={"18px"}
                sx={{display: "block", textAlign:'left'}}
            />
          </article>
          <figure>
            <img src={piezaHero} alt="Figura de LaPieza" />
          </figure>
        </div>
      </Hero>
        </Bg.Linear>
      <InfoSection>
        <div className="info-section main-landing-container-column">
          <Text.Title
              text={t("usability")}
              fontSize='38px'
              textAlign="start"
              sx={{display: "block", marginBottom:'30px'}}
          />
          <Text.Body
              text={t("desc")}
              fontSize={'18px'}
              textAlign="start"
              sx={{display: "block", marginBottom:'60px'}}
          />
          <div className="info-section-list">
            <article>
              <Text.Title
                  text={t("infoFirstTitle")}
                  fontSize={'26px'}
                  textAlign="start"
                  sx={{display: "block", marginBottom:'30px'}}
              />
              <Text.Body
                  text={t("infoFirstDesc")}
                  fontSize={'18px'}
                  textAlign="start"
                  sx={{display: "block", marginBottom:'30px'}}
              />
            </article>
            <article>
              <Text.Title
                  text={t("infoSecondTitle")}
                  fontSize={'26px'}
                  textAlign="start"
                  sx={{display: "block", marginBottom:'30px'}}

              />
              <Text.Body
                  text={t("infoSecondDesc")}
                  fontSize={'18px'}
                  textAlign="start"
                  sx={{display: "block", marginBottom:'30px'}}

              />
            </article>
            <article>
              <Text.Title
                  text={t("infoThirdTitle")}
                  fontSize={'26px'}
                  textAlign="start"
                  sx={{display: "block", marginBottom:'30px'}}
              />
              <Text.Body
                  text={t("infoThirdDesc")}
                  fontSize={'18px'}
                  textAlign="start"
                  sx={{display: "block", marginBottom:'30px'}}
              />
            </article>
            <article>
              <Text.Title
                  text={t("infoFourthTitle")}
                  fontSize={'26px'}
                  textAlign="start"
                  sx={{display: "block", marginBottom:'30px'}}
              />
              <Text.Body
                  text={t("infoFourthDesc")}
                  fontSize={'18px'}
                  textAlign="start"
                  sx={{display: "block", marginBottom:'30px'}}
              />
            </article>
          </div>
        </div>
      </InfoSection>
      <Bg.Gradient>
      <ContactSection>
        <div className="contact-section main-landing-container-row">
          <article>
            <Text.Title
                text={t("contactTitle")}
                textAlign="start"
                fontSize={"38px"}
                sx={{display: "block", marginTop:'50px', marginBottom:'30px'}}
            />
            <Text.Body
                text={t("contactDescription")}
                textAlign="start"
                fontSize={"18px"}
                sx={{display: "block", marginBottom:'20px'}}
            />
              <Button
              href="https://api.whatsapp.com/send/?phone=527771369552&text=¡Hola Alexa! Me interesa saber más sobre los servicios de LaPieza."
              target="_blank"
              rel="noopener noreferrer"
              sx={{
              width: "270px",
              fontSize: "16px",
              mb: 5,
          }}
              >
              <Link className="contact-button" to="/contact">
                  <Text.Body
                      text={t("contactTeam")}
                      color={'primary.white'}
                  />
              </Link>
          </Button>
          </article>
          <figure>
            <img src={pieza} alt="Neutralidad de carbono | LaPieza" />
          </figure>
        </div>
      </ContactSection>
      </Bg.Gradient>
    </Layout>
  );
};

export default Pledge;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
